import { Alert, Container, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom";
import usePageTracking from "../../Components/UsePageTracking";
import HelmetHeader from "../../Components/HelmetHeader";

const VerifyEmail: React.FC = () => {
    const history = useHistory();

    usePageTracking();
    document.title = "Verify Email - CarMeet.Social"
    
    const CreateHelmetHeader: React.FC = () => {
        return(
            <HelmetHeader url={`VerifyEmail`} description={``} quote={``} title={`Verify your email`} />
        )
    }

    if (history.location.search.indexOf("validated=true") > 0)
    {
        return (<>
            <CreateHelmetHeader />
            <Alert variant="success">
            <p className="text-center">Successfully validated user</p>
            <p onClick={() => history.push("login")} className="text-center link">Click here to login.</p>
        </Alert>
        </>)
    } else if (history.location.search.indexOf("validated=false") > 0)
    {
        const index = history.location.search.indexOf("message=");
        let message = "";
        if (index == -1)
        {
            message = "An unexpected error has occurred."
        } else {
            message = history.location.search.substring(index + 8);
        }
        return (
            <>
                <CreateHelmetHeader />
                <Alert variant="danger">
                    <p className="text-center">
                        {decodeURIComponent(message)}
                    </p>
                </Alert>
            </>)
    }

    return (<Container>
        <CreateHelmetHeader />
        <Row>
            &nbsp;
        </Row>
        <Row>
            <p>
                Thank you for registering, please verify your email address.
            </p>
            <p>
                <strong>Note:</strong> This may have ended up in your junk/spam folder.
            </p>
        </Row>
        <Row>
            &nbsp;
        </Row>
    </Container>)
}

export default VerifyEmail;