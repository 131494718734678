import { useEffect, useState } from "react";
import { Button, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ResetPasswordRequestModel, UsersClient } from "../../Client/Client";
import usePageTracking from "../../Components/UsePageTracking";
import HelmetHeader from "../../Components/HelmetHeader";

const ResetPasswordPage: React.FC = () => {
    const history = useHistory();

    const userClient = new UsersClient(process.env.REACT_APP_API_URL);

    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [passwordsMatch, setPasswordMatch] = useState<boolean>(true);

    usePageTracking();

    useEffect(() => {
        if (history.location.search.indexOf("token") < 0 || history.location.search.indexOf("username") < 0)
        {
            history.push('/ResetPassword/Request');
        }
    },[])

    useEffect(() => {
        setPasswordMatch(password === passwordConfirm);
    }, [password, passwordConfirm])

    const resetPassword = () => {
        const searchParams = history.location.search.slice(1).split("&")

        let username = searchParams[0].split("username=")[1] ?? "";
        let passwordToken = "";

        if (username !== "")
        {
            passwordToken = searchParams[1].split("token=")[1];
        } else {
            passwordToken = searchParams[0].split("token=")[1];
            username = searchParams[1].split("username=")[1];
        }

        let resetPasswordBody: ResetPasswordRequestModel = {
            passwordToken: passwordToken,
            username: username,
            password: password,
            confirmPassword: passwordConfirm
        }
        userClient.resetPassword(resetPasswordBody)
            .then(() => history.push("/Login"))
            .catch((ex) => alert(ex));
    }

    return(
        <Container>
            <HelmetHeader description={"Reset your password here to help you be able to login to your account again."} title={"Reset my password"} url={"ResetPassword"} quote={"Reset my password"} />
            <Row>
                &nbsp;
            </Row>
            <Row>
            <Form>
                <InputGroup size="lg">
                    <InputGroup.Text id="inputGroup-sizing-lg">Password</InputGroup.Text>
                    <Form.Control
                        aria-label="Password"
                        aria-describedby="inputGroup-sizing-lg"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        type="Password"
                    />
                </InputGroup>
                <InputGroup size="lg">
                    <InputGroup.Text id="inputGroup-sizing-lg">Confirm Password</InputGroup.Text>
                    <Form.Control
                        aria-label="Password"
                        aria-describedby="inputGroup-sizing-lg"
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        value={passwordConfirm}
                        type="Password"
                    />
                    {
                        !passwordsMatch && password !== "" && (
                            <InputGroup.Text id="inputGroup-sizing-lg" style={{"color": "red"}} title={`Passwords do not match.`}>
                                ☓
                            </InputGroup.Text>
                        )
                    }
                </InputGroup>

                <br />

                {
                    passwordsMatch && password !== "" && (
                        <Button variant="primary" onClick={() => resetPassword()}>
                            Change Password
                        </Button>
                    )
                }
            </Form>
            </Row>
            <Row>
                &nbsp;
            </Row>
        </Container>
    )
}

export default ResetPasswordPage;