import React, {useEffect} from "react";

interface Props {
    advertEnabled: boolean
}

interface Window {
    adsbygoogle: {[key: string]: unknown}[]
}

const AdvertisementPage: React.FC<Props> = ({advertEnabled}) => {

    useEffect(() => {
        let adsbygoogle: any;
        (adsbygoogle = (window as any).adsbygoogle || []).push({});
    },[]);
    
    return(<>
        
        {advertEnabled && (
            <>
                <ins className="adsbygoogle"
                     style={{"display": "block"}}
                     data-ad-client="ca-pub-6626618377099611"
                     data-ad-slot="9613337650"
                     data-ad-format="auto"
                     data-full-width-responsive="true">
                </ins>
            </>)}
    </>);
}

export default AdvertisementPage;
