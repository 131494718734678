import React, {useState} from "react";
import {UserResponseModel, UsersClient, UpdateUserRequestModel} from "../Client/Client";
import {Accordion, Alert, Button, Col, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import ImageUploading, {ImageListType} from "react-images-uploading";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";

interface Props {
    userResponse: UserResponseModel
}

const EditProfileComponent: React.FC<Props> = ({ userResponse }) => {
    const [searchPostcode, setSearchPostcode] = useState<string>(userResponse.defaultSearchPostcode ?? "")
    const accessToken = useSelector((state: RootState) => state.user.accessToken);

    const history = useHistory();
    const usersClient = new UsersClient(process.env.REACT_APP_API_URL);

    const [updateAvatar, setUpdateAvatar] = useState<boolean>(false);
    const [image, setImage] = useState<ImageListType>();
    const [imagesBase64, setImagesBase64] = useState<string>();
    
    const saveChanges = () => {
        console.log(image);
        
        const UpdateUserRequestModel: UpdateUserRequestModel = {
            avatarImageBlob: imagesBase64,
            searchPostcode: searchPostcode
        }
        
        usersClient.updateUser(`bearer ${accessToken}`, UpdateUserRequestModel).then(res => history.go(0)).catch(ex => alert(ex));
    }

    const onImageChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
    ) => {
        // data for submit
        setImage(imageList);

        const dataUrls = imageList.map(x => x.dataURL!);
        setImagesBase64(dataUrls.toString());
    };
    
    return (
        <>
            <Row>
                <Col sm={2}>
                    &nbsp;
                </Col>
                <Col sm={2}>
                    <img src={userResponse.avatarImageUrl!} alt={"Profile Image"} className="rounded-circle user-avatar" />
                    <br />
                    {
                        !updateAvatar && (
                            <Button type="button" onClick={() => setUpdateAvatar(!updateAvatar)}>
                                Update Avatar
                            </Button>       
                        )
                    }

                    {
                        updateAvatar && (
                            <>
                                <ImageUploading
                                    value={image!}
                                    onChange={onImageChange}
                                >
                                    {({
                                          imageList,
                                          onImageUpload,
                                          onImageRemoveAll,
                                          onImageUpdate,
                                          onImageRemove,
                                          isDragging,
                                          dragProps,
                                          errors
                                      }) => (
                                        <>
                                            <div className="upload__image-wrapper">
                                                <button
                                                    style={isDragging ? { color: "red" } : undefined}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                >
                                                    Click here to upload your image(s)
                                                </button>
                                                &nbsp;
                                                <button onClick={onImageRemoveAll}>Remove all images</button>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <img src={image.dataURL} alt="" width="100" />
                                                        <div className="image-item__btn-wrapper">
                                                            <button onClick={() => onImageUpdate(index)}>Update</button>
                                                            <button onClick={() => onImageRemove(index)}>Remove</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div>
                                                {errors != null && errors.maxNumber && <span style={{color: "red"}}>Number of selected images exceed 50</span>}
                                            </div>
                                        </>
                                    )}
                                </ImageUploading>
                            </>
                        )
                    }
                </Col>
                <Col sm={6}>
                    <p>
                        Search Postcode: <input type={"text"} value={searchPostcode} onChange={(e) => setSearchPostcode(e.target.value)} />
                    </p>
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    &nbsp;
                </Col>
                <Col sm={2}>
                    <h4>
                        {userResponse.userName}
                    </h4>
                </Col>
                <Col sm={6}>
                    <Accordion>
                        <Accordion.Header>
                            Why is my postcode needed?
                        </Accordion.Header>
                        <Accordion.Body>
                            This is the default postcode for filters and can be left blank if you want to.<br />
                            <i>Helpful hint:</i> This could be a near by postcode if you would rather not add a personal postcode.
                        </Accordion.Body>
                    </Accordion>
                    
                    <p>
                        <Button variant={"success"} type={"button"} onClick={() => saveChanges()}>
                            Save Changes
                        </Button>
                    </p>
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
        </>
    )
}

export default EditProfileComponent;