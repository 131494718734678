import { useState } from "react";
import { Button, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CreateUserModel, UsersClient } from "../../Client/Client";
import usePageTracking from "../../Components/UsePageTracking";
import HelmetHeader from "../../Components/HelmetHeader";

const RegisterPage: React.FC = () => {
    const history = useHistory();

    const userClient = new UsersClient(process.env.REACT_APP_API_URL);

    const [username, setUsername] = useState<string>('');
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [validUsername, setValidUsername] = useState<boolean>(false);

    const checkUserExists = (inputUsername: string) => {
        // Remove non-alphanumeric characters from username
        inputUsername = inputUsername.replace(/[^a-z0-9]/gi, '');
        setUsername(inputUsername);

        userClient.usernameIsValid(inputUsername).then((response) => setValidUsername(response)).catch(() => setValidUsername(false));
    }

    const createUser = () => {
        let registerBody: CreateUserModel = {
            username: username,
            emailAddress: emailAddress,
            password: password
        }
        userClient.register(registerBody)
            .then(() => history.push("/VerifyEmail"))
            .catch((ex) => alert(ex));
    }

    usePageTracking();

    return (
        <Container>
            <HelmetHeader url={`Register`} description={`Create an account`} quote={`Register for an account`} title={`Register for an account`} />
            <Row>
                <Form>
                    <InputGroup size="lg">
                        <InputGroup.Text id="inputGroup-sizing-lg">Username</InputGroup.Text>
                        <Form.Control
                            placeholder="Enter username"
                            aria-label="username"
                            aria-describedby="inputGroup-sizing-lg"
                            onChange={(e) => checkUserExists(e.target.value)}
                            value={username}
                        />
                        {
                            validUsername && username != null && (
                                <InputGroup.Text id="inputGroup-sizing-lg" style={{"color": "green"}}>✓</InputGroup.Text>
                            )
                        }
                        {
                            !validUsername && username !== "" && (
                                <InputGroup.Text id="inputGroup-sizing-lg" style={{"color": "red"}} title={`Username '${username}' is not valid`}>
                                    ☓
                                </InputGroup.Text>
                            )
                        }
                    </InputGroup>
                    <br />

                    <InputGroup size="lg">
                        <InputGroup.Text id="inputGroup-sizing-lg">Email Address</InputGroup.Text>
                        <Form.Control
                            placeholder="Enter email address"
                            aria-label="EmailAddress"
                            aria-describedby="inputGroup-sizing-lg"
                            onChange={(e) => setEmailAddress(e.target.value)}
                            type="email"
                        />
                    </InputGroup>
                    <InputGroup.Text className="text-muted">
                            We'll never share your email with anyone else and will only use it to email you notifications.
                    </InputGroup.Text>
                    <br />

                    <InputGroup size="lg">
                        <InputGroup.Text id="inputGroup-sizing-lg">Password</InputGroup.Text>
                        <Form.Control
                            placeholder="Enter password"
                            aria-label="password"
                            aria-describedby="inputGroup-sizing-lg"
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                        />
                    </InputGroup>
                    <br />

                    <Button variant="primary" type="button" onClick={() => createUser()} disabled={!validUsername || username === "" || password === "" || emailAddress === ""}>
                        Submit
                    </Button>
                    &nbsp;
                    <Button variant="danger" type="button" onClick={() => history.push("/")}>
                        Cancel
                    </Button>
                </Form>
            </Row>
        </Container>
    )
}

export default RegisterPage;