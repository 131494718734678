import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoogleTag from "../GoogleTag";
import { RootState } from "../store/store";

interface props {
    showFeaturedEvents: boolean,
    featuredEvents: Array<FeaturedEvent>
}

export type FeaturedEvent = {
    eventName: string;
    eventUrl: string;
}

const FooterBar: React.FC<props> = ({ showFeaturedEvents, featuredEvents }) => {
    const history = useHistory();
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);

    return (
      <>
    <footer className="text-center text-lg-start bg-light text-muted">
  
  <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
  
    <div className="me-5 d-none d-lg-block">
      <span>Get connected with us on social networks:</span>
    </div>
  
    <div>
      <a href="https://www.facebook.com/profile.php?id=100088217461526" target="_blank" className="me-4 text-reset">
        <i className="fab fa-facebook-f"></i>
      </a>
      {/* <a href="" target="_blank" className="me-4 text-reset">
        <i className="fab fa-twitter"></i>
      </a> */}
      <a href="https://www.instagram.com/carmeet.social/" target="_blank" className="me-4 text-reset">
        <i className="fab fa-instagram"></i>
      </a>
      {/* <a href="" target="_blank" className="me-4 text-reset">
        <i className="fab fa-linkedin"></i>
      </a> */}
    </div>
  
  </section>
  
  <section className="">
    <div className="container text-center text-md-center mt-5">
      
      <div className="row mt-3">
      
      <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
      
          <h6 className="fw-bold mb-4">
            CarMeet.Social
          </h6>
          <p>
            Find a car meet that interests you, get there and meet new people with the same interests as you.
          </p>
        </div>
      

      
        {showFeaturedEvents && featuredEvents.length > 0 && (
            <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4 p-6">
      
            <h6 className="text-uppercase fw-bold mb-4">
              Featured Events
            </h6>

            { featuredEvents.map((event) => {
                return(<p>
                    <a href={event.eventUrl} className="text-reset">{event.eventName}</a>
                  </p>)
            })}

          </div>
        )}
        
        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
        
          <h6 className="text-uppercase fw-bold mb-4">
            Useful links
          </h6>
          <p className="text-reset link" onClick={() => history.push("/")}>
            Home
          </p>
          <p className="text-reset link" onClick={() => history.push("/Events")}>
            Events
          </p>
          {
            isAuthenticated && (
                <p className="text-reset link" onClick={() => history.push("/Events/Create")}>
                    Create Event
                </p>
            )
          }
          <p className="text-reset link" onClick={() => history.push("/FAQ")}>
            Frequently Asked Questions
          </p>
          <p className="text-reset link" onClick={() => history.push("/AdvertiseWithUs")}>
              Advertise With Us
          </p>
          <p>
            <a href="https://www.carmeet.social/terms.html" target="_blank" className="text-reset link">
              Terms Of Use
            </a>
          </p>
          <p>
            <a href="https://www.carmeet.social/cookie.html" target="_blank" className="text-reset link">
              Cookie Policy
            </a>
          </p>
        </div>
        
      </div>
      
    </div>
  </section>
  
  <div className="row mt-3">
      
      <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
            &copy; {new Date().getFullYear()} Copyright &nbsp;
            <a className="text-reset fw-bold" href="https://carmeet.social">CarMeet.Social</a>
      </div>

      <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
            Website Design and Development by <a href="https://www.cw92.dev/" target="_blank" title="Web and App Design">Concierge Web Solutions</a>
      </div>
    </div>
  
</footer>
{
  window.location.host == "carmeet.social" && (
    <div dangerouslySetInnerHTML={GoogleTag()} />
  )
}
</>
    );
}

export default FooterBar;