import React, { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import ModalComponent from './ModalComponent';

interface Props {
    imageUrls: Array<string>
}

const ImageSlideshow: React.FC<Props> = ({ imageUrls }) => {     
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const divStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundSize: 'contain',
      height: '40vh'
  }

  return (
    <div className="slide-container">
      <Slide indicators={true} duration={3000} autoplay={!isOpen}>
        {imageUrls.map((imageUrl, index: number)=> (
          <div key={index}>
            <div className='imageSlider' style={{ ...divStyle, 'backgroundImage': `url('${imageUrl}')` }} onClick={() => {
              setIsOpen(true);
              setImageUrl(imageUrl);
            }} 
            >
            </div>
          </div>
        ))} 
      </Slide>      
        
      <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} imageUrl={imageUrl} />
    </div>
  )
}

export default ImageSlideshow;