import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationUser } from "../../Client/Client";

interface SliceState {
    isAuthenticated: boolean
    user: ApplicationUser | undefined
    accessToken: string | undefined
    showDistance: boolean
}

const initialState: SliceState = {
    isAuthenticated: false,
    user: undefined,
    accessToken: undefined,
    showDistance: false
}

const userSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            if (action.payload !== undefined) state.isAuthenticated = action.payload;
        },
        setUser: (state, action: PayloadAction<ApplicationUser>) => {
            state.user = action.payload;
        },
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        setShowDistance: (state, action: PayloadAction<boolean>) => {
            state.showDistance = action.payload;
        } 
    }
})

export const { setIsAuthenticated, setUser, setAccessToken, setShowDistance } = userSlice.actions

export default userSlice.reducer;