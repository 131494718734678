import React, { useEffect, useState } from "react";
import {Col, Container, Row, Form, Pagination, InputGroup, Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {CountryModel, CountyModel, EventResponseModel, EventsClient, FilterClient} from "../../Client/Client";
import EventItem from "../../Components/EventItem";
import SpinnerComponent from "../../Components/SpinnerComponent";
import usePageTracking from "../../Components/UsePageTracking";
import { RootState } from "../../store/store";
import HelmetHeader from "../../Components/HelmetHeader";
import {useHistory, useLocation} from "react-router-dom";
import {setShowDistance} from "../../store/userSlice/userSlice";
import PaginationComponent from "../../Components/PaginationComponent";
import Moment from "moment/moment";

const EventsPage: React.FC = () => {
    const {isAuthenticated, accessToken, showDistance} = useSelector((state: RootState) => state.user);
    
    const eventsClient = new EventsClient(process.env.REACT_APP_API_URL);
    const filterClient = new FilterClient(process.env.REACT_APP_API_URL);
    
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [title, setTitle] = useState<string>("");

    const eventsCacheMap: Map<string, Promise<Array<EventResponseModel>>> = new Map();
    const [events, setEvents] = useState<Array<EventResponseModel>>([]);
    const [displayEvents, setDisplayEvents] = useState<Array<EventResponseModel>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    
    const [countries, setCountries] = useState<Array<CountryModel>>([]);
    const [counties, setCounties] = useState<Array<CountyModel>>([]);
    
    const [eventFilterName, setEventFilterName] = useState<string>();
    const [eventFilterDate, setEventFilterDate] = useState<Date>();
    const [eventCountryFilter, setEventCountryFilter] = useState<CountryModel>();
    const [eventCountyFilter, setEventCountyFilter] = useState<Array<string>>([]);

    usePageTracking();

    useEffect(() => {
        setIsLoading(true);
        
        const getFutureEventsWithNoDistance = (): Promise<Array<EventResponseModel>> | undefined => {
            if (!eventsCacheMap.has("futureNoDistance")) {
                eventsCacheMap.set("futureNoDistance", eventsClient.getUpcoming());
            }
            return eventsCacheMap.get("futureNoDistance");
        };

        const getFutureEventsWithDistance = (): Promise<Array<EventResponseModel>> | undefined => {
            if (!eventsCacheMap.has("futureWithDistance")) {
                eventsCacheMap.set("futureWithDistance", eventsClient.getUpcomingWithDistance(`bearer ${accessToken}`));
            }
            return eventsCacheMap.get("futureWithDistance");
        };
        const getPastEventsWithNoDistance = (): Promise<Array<EventResponseModel>> | undefined => {
            if (!eventsCacheMap.has("pastNoDistance")) {
                eventsCacheMap.set("pastNoDistance", eventsClient.getPrevious());
            }
            return eventsCacheMap.get("pastNoDistance");
        };

        const getAllEventsWithNoDistance = (): Promise<Array<EventResponseModel>> | undefined => {
            if (!eventsCacheMap.has("allNoDistance")) {
                eventsCacheMap.set("allNoDistance", eventsClient.get());
            }
            return eventsCacheMap.get("allNoDistance");
        };
        
        if (location.pathname.toLowerCase() == "/events/upcoming")
        {
            setTitle("Upcoming Events");
            (showDistance && isAuthenticated ? getFutureEventsWithDistance() : getFutureEventsWithNoDistance())!.then(events => {
                getTotalPages(events)
                setEvents(events);
            })
        } else if (location.pathname.toLowerCase() == "/events/previous")
        {
            setTitle("Previous Events");
            getPastEventsWithNoDistance()!.then(events => {
                getTotalPages(events)
                setEvents(events);
            })
        } else {
            setTitle("All Events");
            getAllEventsWithNoDistance()!.then(events => {
                getTotalPages(events)
                setEvents(events);
            })  
        }
        
        filterClient.getCountries().then((response) => {
            setCountries(response);
        })
      }, [location, showDistance])
    
    useEffect(() => {
        if(eventCountryFilter != null){
            filterClient.getCounties(eventCountryFilter).then((response) => {
                setCounties(response);
            })   
        }
    }, [eventCountryFilter])

    const getTotalPages = (events: Array<EventResponseModel>) => {
        setIsLoading(false);
        setTotalPages(Math.ceil(events.length / 10));
        setDisplayEvents(events);
    }
    
    const filterEventByName = (eventName: string) => {
        setEventFilterName(eventName);
    }
    
    const filterByDate = (eventDate: string) => {
        if (eventDate !== "")
        {
            const parsedDate = new Date(eventDate);
            setEventFilterDate(parsedDate);
        } else {
            setEventFilterDate(undefined);
        }
    }
    
    const getFilterDateValue = () => {
        if (eventFilterDate != null)
        {
            return Moment(eventFilterDate).format('yyyy-MM-DD');
        }
        
        return "";
    }
    
    const filterByCountry = (eventCountryId: string) => {
        setEventCountryFilter(countries.find(x => x.id == Number.parseInt(eventCountryId)));
    }

    const filterByCounty = (eventCountyId: string) => {
        console.log(eventCountyId);
        
        let county = counties.find(x => x.id == Number.parseInt(eventCountyId));
        if (county == null)
        {
            return;
        }
        
        let eventCounties = eventCountyFilter;
        if(!eventCounties.find(x => x == county)){
            eventCounties.push(`${county.id!}`);
        }
        
        setEventCountyFilter(eventCounties);
    }
    
    const applyFilters = () => {
        filterEvents();
    }
    
    const clearFilters = () => {
        setEventFilterDate(undefined);
        setEventFilterName(undefined);
        setEventCountryFilter(undefined);
        setEventCountyFilter([]);
        setDisplayEvents(events);
    }
    
    const filterEvents = () => {
        let filteredEvents = events;
        
        if (eventFilterName != null && eventFilterName !== "") {
            filteredEvents = filteredEvents.filter(x => x.name!.toLowerCase().includes(eventFilterName.toLowerCase()))
        }
        
        if (eventFilterDate != null){
            filteredEvents = filteredEvents.filter(x => Moment(x.startDate!).format('yyyy-MM-DD') === Moment(eventFilterDate).format('yyyy-MM-DD'));
        }
        
        if (eventCountryFilter != null)
        {
            filteredEvents = filteredEvents.filter(x => x.country?.id == eventCountryFilter.id);
        }

        if (eventCountyFilter.length > 0)
        {
            filteredEvents = filteredEvents.filter(x => x.county?.id != null && eventCountyFilter.includes(`${x.county.id}`));
        }
        
        setDisplayFilter(filteredEvents);
    }
    
    const setDisplayFilter = (eventsArray: EventResponseModel[]) => {
        setDisplayEvents(eventsArray);
        getTotalPages(eventsArray)
    }

      if (isLoading) {
          return (<SpinnerComponent/>)
      }

    // @ts-ignore
    return (<Container>
        <HelmetHeader url={`Events`} description={`See all car meetups that you are missing out on.`} quote={`Check out all of our events by visiting https://Carmeet.Social/Events`} title={title} />
        <Row>
            &nbsp;
        </Row>
        <Row>
        <Col md={showFilter ? 3 : 1} className={showFilter ? "bg-light" : ""}>
            {
                !showFilter && (
                    <div title={"Show Filters"} onClick={() => setShowFilter(!showFilter)}>
                        <i className="fa-solid fa-chevron-right"></i>
                        <i className="fa-solid fa-chevron-right"></i>
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                )
            }
            {
                showFilter && (
                    <>
                        <i className={"fa-solid fa-times float-right"} onClick={() => setShowFilter(!showFilter)}></i>
                        <br />
                        
                        {
                            isAuthenticated && (
                            <Form.Check 
                                type={"checkbox"}
                                label={"Show distances to event"}
                                checked={showDistance}
                                onChange={() => dispatch(setShowDistance(!showDistance))}
                            />)
                        }
                        <br />
                        
                        <label>
                            Filter by name: 
                        </label>
                        <input type={"text"} className={"form-control"} onChange={(e) => filterEventByName(e.target.value)} value={eventFilterName ?? ""} />
                        <br />
                        
                        <label>
                            Filter by date:
                        </label>
                        <input type={"date"} className={"form-control"} onChange={(e) => filterByDate(e.target.value)} value={getFilterDateValue()} />
                        <br />

                        {
                            countries.length > 0 && (
                                <>
                                    <label className="form-label select-label">Filter by Country:</label>
                                    <select className="select" value={eventCountryFilter?.id ?? 0} onChange={(e) => filterByCountry(e.target.value)}>
                                        <option value="0" disabled={true}>Select a country</option>
                                        {
                                            countries.map((country) => {
                                                return (<option key={country.id} value={country.id}>{country.name}</option>)
                                            })
                                        }
                                    </select>
                                    <br />
                                </>
                            )
                        }

                        {
                            eventCountryFilter != null && counties.length > 0 && (
                                <>
                                    <label>
                                        Filter by County:
                                    </label>
                                        <Form>
                                            <Form.Control as="select" multiple onChange={(e) => filterByCounty(e.target.value)} value={eventCountyFilter}>
                                                {counties.map(county => (
                                                    <option key={county.id} value={`${county.id}`}>
                                                        {county.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form>
                                    <br />
                                </>
                            )
                        }

                        <br />
                        <Button type={"button"} variant={"success"} onClick={() => applyFilters()}>
                            Apply Filters   
                        </Button>
                        &nbsp;
                        <Button type={"button"} variant={"warning"} onClick={() => clearFilters()}>
                            Clear Filters
                        </Button>
                        </>
                )
            }
        </Col>
        <Col xs={1}>
            <p>
                &nbsp;
            </p>
        </Col>
        <Col>
            {
                displayEvents.length <= 0 && (
                    <>
                        <h4>No Events Found</h4>
                        {
                            isAuthenticated && (
                                <p onClick={() => history.push(isAuthenticated ? "/Events/Create" : "/Login?ReturnUrl=/Events/Create")}>Click here to create one</p>
                            )
                        }
                    </>
                )
            }
            {
                displayEvents.slice((currentPage - 1) * 10, currentPage * 10).map((event) => {
                    return (<EventItem event={event} key={event.id} />)
                })
            }
        </Col>
        </Row>
        <Row>
            <Pagination>
                <Pagination.First onClick={() => setCurrentPage(1)} />
                <Pagination.Prev disabled={currentPage === 1} onClick={() => currentPage > 1 ? setCurrentPage(currentPage - 1) : null} />
                <PaginationComponent totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                <Pagination.Next disabled={currentPage === totalPages} onClick={() => currentPage < totalPages ? setCurrentPage(currentPage + 1) : null} />
                <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
            </Pagination>
        </Row>
    </Container>)
}

export default EventsPage;