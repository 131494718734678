import React from "react";
import {Button, Modal} from "react-bootstrap";

interface Props {
    okText: string
    cancelText: string
    confirmation: string
    show: boolean
    proceed: () => void;
    cancel: () => void;
}
const ConfirmationComponent: React.FC<Props> = ({ okText, cancelText, confirmation, proceed, show, cancel}) => {
    return(<Modal
        size="sm"
        show={show}
        onHide={() => cancel()}
        keyboard={true}
        backdrop="static"
        centered
    >
        <Modal.Body>
            {confirmation}
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="primary"
                onClick={() => proceed()}
            >
                {okText}
            </Button>
            {
                cancelText != null && cancelText != "" && (
                    <Button
                        variant={"warning"}
                        onClick={() => cancel()}
                    >
                        {cancelText}
                    </Button>
                )
            }
        </Modal.Footer>
    </Modal>)
}

export default ConfirmationComponent;