import React, {useState} from "react";
import {UserResponseModel} from "../Client/Client";
import {Col, Container, Row} from "react-bootstrap";

interface Props {
    userResponse: UserResponseModel
    isCurrentUser: boolean
}

const ViewProfileComponent: React.FC<Props> = ({ userResponse, isCurrentUser,  }) => {    
    return (
        <>
            <Row>
                <Col sm={2}>
                    &nbsp;
                </Col>
                <Col sm={2}>
                    <img src={userResponse.avatarImageUrl!} alt={"Profile Image"} className="rounded-circle user-avatar" />
                </Col>
                {
                    isCurrentUser && (
                        <Col sm={6}>
                            <p>
                                Credits: {userResponse.credits}
                            </p>
                            <p title={"This is the default postcode for filters.<br /> This could be a near by postcode if you would rather not add a personal postcode."}>
                                Search Postcode: {userResponse.defaultSearchPostcode}
                            </p>
                        </Col>
                    )
                }
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    &nbsp;
                </Col>
                <Col sm={2}>
                    <h4>
                        {userResponse.userName}
                    </h4>
                </Col>
                <Col sm={6}>
                    &nbsp;
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
        </>
    )
}

export default ViewProfileComponent;