import { Accordion } from "react-bootstrap";

interface Props {
    title: string
    description: string
}

const FAQItem: React.FC<Props> = ({ title, description }) => {
    return (
        <>
            <Accordion.Header>
                {title}
            </Accordion.Header>
            <Accordion.Body>
                <div dangerouslySetInnerHTML={{ __html: description! }}></div>
            </Accordion.Body>
        </>
    )
}

export default FAQItem;