import MD5 from "crypto-js/md5";
import React from "react";

export interface Props {
    userName: string
    avatarUrl: string
}

const avatarItem: React.FC<Props> = ({ userName, avatarUrl }) => {
    return(
        <p>
            <img src={avatarUrl} alt="Image Avatar" className="rounded-circle user-avatar" />
            &nbsp;
            {userName}
        </p>
    );
}

export default avatarItem;