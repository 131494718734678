import React, { ChangeEvent, useEffect, useState } from "react";
import { Alert, Button, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { OidcConfigurationClient } from "../../Client/Client";
import SpinnerComponent from "../../Components/SpinnerComponent";
import usePageTracking from "../../Components/UsePageTracking";
import { RootState } from "../../store/store";
import { setAccessToken, setIsAuthenticated } from "../../store/userSlice/userSlice";
import HelmetHeader from "../../Components/HelmetHeader";

const LoginPage: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const accessToken = useSelector((state: RootState) => state.user.accessToken);

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    usePageTracking();

    useEffect(() => {
        if (isAuthenticated && accessToken !== "")
        {
            if (location.search.indexOf("ReturnUrl"))
            {
                const searchParams = history.location.search.slice(1).split("&");
                let returnUrl = searchParams[0].split("ReturnUrl=")[1] ?? "/";
                history.push(returnUrl);
            } else {
                history.push("/");
                return;
            }
        }
    }, [isAuthenticated, accessToken])

    if (isLoading)
    {
    return(<SpinnerComponent />)
    }

    const login = () => {
        if (username === '' || password === '')
        {
            alert("Invalid username or password entered.")
        }

        setIsLoading(true);

        const oidcConfigClient = new OidcConfigurationClient(process.env.REACT_APP_API_URL);
          oidcConfigClient.getClientParameters().then((response: any) => {
            const clientId = process.env.REACT_APP_CLIENT_ID!;
            const details: any = {
                'client_id': clientId,
                'client_secret': response.cs,
                'scope': 'profile offline_access',
                'grant_type': 'password',
                'username': username,
                'password': password
            }

          let formBody = [];
          
          for (var property in details) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
          }

          const body = formBody.join("&");

          fetch(`${process.env.REACT_APP_API_URL ?? ''}/connect/token`, {
              method: 'POST',
              cache: 'no-cache',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
              },
              body: body
          })
          .then((response) => response.json())
          .then((data) => {
            if (data.access_token == null)
            {
                throw(data.error_description);
            }

            let expiryDate = new Date();
            expiryDate.setHours(expiryDate.getHours() + data.expires_in / 60 / 60);

            sessionStorage.setItem("accessToken", data.access_token);
            sessionStorage.setItem("expires", expiryDate.toString())
            sessionStorage.setItem("refreshToken", data.refresh_token);

            dispatch(setAccessToken(data.access_token));
            dispatch(setIsAuthenticated(true));
            }).catch((error) => {
                const errorMessage = error.replaceAll("_", " ");
                setIsError(true);
                setErrorMessage(errorMessage);
                setIsLoading(false);
            });
        })
    }

    const onChangeDetails = (type: string, value: string) => {
        setIsError(false);
        if (type === "username")
        {
            setUsername(value);
        } else {
            setPassword(value);
        }
    }

    return(
        <Container>
            <HelmetHeader url={`Login`} description={`Access your account`} quote={`Login to your account`} title={`Login to your account`} />
            <Row>
                &nbsp;
            </Row>
            <Row>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" placeholder="Enter username" onChange={(e) => onChangeDetails("username", e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(e) => onChangeDetails("password", e.target.value)} onKeyDown={(e) => e.keyCode == 13 ? login() : null} />
                </Form.Group>
                {
                    isError && (
                        <Alert variant="danger">
                            {errorMessage}
                        </Alert>
                    )
                }
                <Button variant="primary" onClick={() => login()}>
                    Submit
                </Button>
                &nbsp;
                <Button variant="warning" onClick={() => history.push("/ResetPassword/Request")}>
                    Forgot Password
                </Button>
                &nbsp;
                <Button variant="warning" onClick={() => history.push("/RetrieveUsername")}>
                    Forgot Username
                </Button>
                <br /><br />
                <h4 className="text-reset link" onClick={() => history.push("/register")}>Don't have an account, click here to create one now</h4>
            </Form>
            </Row>
            <Row>
                &nbsp;
            </Row>
        </Container>
    )
}

export default LoginPage;