import { Dispatch, SetStateAction } from "react"
import { Modal } from "react-bootstrap"

interface Props {
    imageUrl: string
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

const ModalComponent: React.FC<Props> = ({ imageUrl, isOpen, setIsOpen }) => {
    return (
    <Modal show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Body>
        <img 
            src={imageUrl} 
            className="img-fluid m-1 p-1"
        />
        </Modal.Body>
    </Modal>
)
}

export default ModalComponent;  