import { useEffect, useState } from "react";
import { Accordion, Container, Row } from "react-bootstrap";
import { FAQClient, FrequentlyAskedQuestionModel } from "../Client/Client";
import FAQItem from "../Components/FAQItem";
import SpinnerComponent from "../Components/SpinnerComponent";
import HelmetHeader from "../Components/HelmetHeader";

const FAQPage:React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const faqsClient = new FAQClient(process.env.REACT_APP_API_URL);

    const [faqs, setFaqs] = useState<Array<FrequentlyAskedQuestionModel>>([]);

    useEffect(() => {
        if (faqs.length >= 0)
        {
            const getFaqs = async () => {
                let faqsResponse = (await faqsClient.getAll());
                setFaqs(faqsResponse);
                setIsLoading(false);
            }
            getFaqs().catch(e =>
                {
                    console.error(e);
                    setIsLoading(false);
                });
        }
      }, [window.location])

    if (isLoading)
    {
        return(<SpinnerComponent />)
    }

    return(<>
        <Container>
            <HelmetHeader description={"Is there something you are unsure about, take a look in here to see if we have the answer."} title={"Frequently Asked Questions"} url={"FAQ"} quote={"Frequently asked questions may resolve a question you have."} />
            <Row>
                &nbsp;
            </Row>
            <Row>
                <h3 className="text-center">
                    Frequently Asked Questions
                </h3>
            </Row>
            <Row>
                &nbsp;
            </Row>
            <Row>
                <Accordion>
                {
                    faqs?.map((faq) => {
                        return(
                            <Accordion.Item eventKey={`${faq.id!}`} key={faq.id!}>
                                <FAQItem title={faq.title!} description={faq.body!} />
                            </Accordion.Item>
                        )
                    })        
                }
                </Accordion>
            </Row>
        </Container>
    </>)
}

export default FAQPage;