import React from 'react';
import Navbar from './Components/NavBar';
import './App.css';
import {  BrowserRouter as Router, Route, Switch} from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import HomePage from './Pages/Homepage';
import EventsPage from './Pages/Events/EventsPage';
import AdvertisementPage from './Pages/AdvertisementPage';
import { Container } from 'react-bootstrap';
import EventPage from './Pages/Events/EventPage';
import LoginPage from './Pages/Account/LoginPage';
import CreateEventPage from './Pages/Events/CreateEventPage';
import RegisterPage from './Pages/Account/RegisterPage';
import VerifyEmail from './Pages/Account/VerifyEmail';
import ResetPasswordRequestPage from './Pages/Account/ResetPasswordRequestPage';
import ResetPasswordPage from './Pages/Account/ResetPasswordPage';
import FooterBar from './Components/FooterBar';

import { FeaturedEvent } from './Components/FooterBar';
import FAQPage from './Pages/FAQPage';
import RetrieveUsernameRequestPage from './Pages/Account/RetrieveUsernameRequestPage';
import AdvertiseWithUsPage from "./Pages/AdvertiseWithUsPage";
import ProfilePage from "./Pages/Profile/ProfilePage";

function App() {
  return (
    <Router>
      <div className='app'>
        <Navbar />
        <Switch>
          <Route path="/Events/Create">
            <Container>
              <CreateEventPage />
            </Container>
          </Route>
          <Route path={["/Events/Upcoming", "/Events/Previous"]}>
            <EventsPage />
          </Route>
          <Route path="/Events/:id">
            <Container>
              <EventPage />
            </Container>
          </Route>
          <Route path="/Events">
            <EventsPage />
          </Route>
          <Route path="/Login">
            <LoginPage />
          </Route>
          <Route path="/Register">
            <RegisterPage />
          </Route>
          <Route path="/VerifyEmail">
            <VerifyEmail />
          </Route>
          <Route path="/RetrieveUsername">
            <RetrieveUsernameRequestPage />
          </Route>
          <Route path="/ResetPassword/Request">
            <ResetPasswordRequestPage />
          </Route>
          <Route path="/ResetPassword">
            <ResetPasswordPage />
          </Route>
          <Route path="/FAQ">
            <FAQPage />
          </Route>
          <Route path={"/AdvertiseWithUs"}>
            <AdvertiseWithUsPage />
          </Route>
          <Route path={"/Profile/:id"}>
            <ProfilePage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
        <br />
        <AdvertisementPage advertEnabled={true} />
        <br />
        <FooterBar showFeaturedEvents={false} featuredEvents={[]} />
      </div>
    </Router>
  );
}

export default App;
