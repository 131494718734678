import React, { Component } from "react";
import { Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import usePageTracking from "../Components/UsePageTracking";
import { RootState } from "../store/store";
import HelmetHeader from "../Components/HelmetHeader";

const HomePage: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const history = useHistory();

    usePageTracking();

    return (
        <Container className="mt-12 flex flex-col justify-center items-center text-center">
            <HelmetHeader description={"CarMeet.Social is a social platform to help you find a new meetup to show off your car."} title={"Welcome"} url={""} quote={"Welcome to CarMeet.Social"} />
            <Row>
                &nbsp;
            </Row>
            <Row>
            <h3 className="text-center text-xl sm:text-2xl mxl:text-3xl mxl:text-4xl font-extrabold font-semibold text-black">
                    <div>Show off your pride and joy</div>
                </h3>
                <div className="mt-4 text-gray-600 mt-3 max-w-md mx-auto text-center text-base sm:text-lg md:mt-5 mxl:text-xl md:max-w-3xl">
                    <div>
                        CarMeet.Social is built to help car owners organise, plan and promote meet ups.
                    </div>
                </div>
                <div className="flex justify-center items-center gap-x-4 mt-4 w-full">
                    <Button 
                        type="button"
                        variant="Success"
                        className="bg-lightGreen text-white"
                        onClick={() => history.push("/Events/Upcoming")}   
                    >
                        View All Upcoming Events
                    </Button>
                    &nbsp;
                    <Button 
                        type="button"
                        className="bg-lightGreen text-white"
                        variant="Success"
                        onClick={() => history.push(isAuthenticated ? "/Events/Create" : "/Login?ReturnUrl=/Events/Create")}
                    >
                        Register your event today
                    </Button>
                </div>
            </Row>
            
            <Row>
                <p>
                    &nbsp;
                </p>
            </Row>

            <Row>
                <Col className="flex items-center justify-center h-8 w-8 xlg:h-10 xlg:w-10 rounded-md">
                    <div className="flex items-center justify-center h-8 w-8 xlg:h-10 xlg:w-10 rounded-md bg-lightGreen text-white">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="35" width="35" xmlns="http://www.w3.org/2000/svg" className="items-center">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="2" y1="12" x2="22" y2="12"></line>
                            <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                        </svg>
                    </div>
                    
                    <div className="mt-3">
                        <dt className="text-sm sm:text-lg leading-6 font-medium text-gray-900">
                            <div>Meet Your Community</div>
                        </dt>
                        <dd className="mt-2 text-xs sm:text-sm text-gray-500">
                            <div>We help you find and connect with car owners around you and around the country.</div>
                        </dd>
                    </div>
                </Col>

                <Col className="flex items-center justify-center h-8 w-8 xlg:h-10 xlg:w-10 rounded-md">
                    <div className="flex items-center justify-center h-8 w-8 xlg:h-10 xlg:w-10 rounded-md bg-lightGreen text-white">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="35" width="35" xmlns="http://www.w3.org/2000/svg" className="items-center">
                            <g>
                            <path fill="none" d="M0 0H24V24H0z"></path>
                            <path d="M13 2v1.278l5 1.668 3.632-1.21.633 1.896-3.032 1.011 3.096 8.512C21.237 16.292 19.7 17 18 17c-1.701 0-3.237-.708-4.329-1.845l3.094-8.512L13 5.387V19H17v2H7v-2h4V5.387L7.232 6.643l3.096 8.512C9.237 16.292 7.7 17 6 17c-1.701 0-3.237-.708-4.329-1.845l3.094-8.512-3.03-1.01.633-1.898L6 4.945l5-1.667V2h2zm5 7.103l-1.958 5.386c.587.331 1.257.511 1.958.511.7 0 1.37-.18 1.958-.51L18 9.102zm-12 0l-1.958 5.386C4.629 14.82 5.299 15 6 15c.7 0 1.37-.18 1.958-.51L6 9.102z"></path>
                            </g>
                        </svg>
                    </div>
                    <div className="mt-3">
                        <dt className="text-sm sm:text-lg leading-6 font-medium text-gray-900">
                            <div>Organise car meets</div>
                        </dt>
                        <dd className="mt-2 text-xs sm:text-sm text-gray-500">
                            <div>Host an event to meet other car owners in your area and discover new modified cars.</div>
                        </dd>
                    </div>
                </Col>

                <Col className="flex items-center justify-center h-8 w-8 xlg:h-10 xlg:w-10 rounded-md">
                    <div className="flex items-center justify-center h-8 w-8 xlg:h-10 xlg:w-10 rounded-md bg-lightGreen text-white">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="35" width="35" xmlns="http://www.w3.org/2000/svg" className="items-center">
                            <path d="M848 359.3H627.7L825.8 109c4.1-5.3.4-13-6.3-13H436c-2.8 0-5.5 1.5-6.9 4L170 547.5c-3.1 5.3.7 12 6.9 12h174.4l-89.4 357.6c-1.9 7.8 7.5 13.3 13.3 7.7L853.5 373c5.2-4.9 1.7-13.7-5.5-13.7zM378.2 732.5l60.3-241H281.1l189.6-327.4h224.6L487 427.4h211L378.2 732.5z"></path>
                        </svg>
                    </div>

                    <div className="mt-3">
                        <dt className="text-sm sm:text-lg leading-6 font-medium text-gray-900">
                            <div>Find your car meet</div>
                        </dt>
                        <dd className="mt-2 text-xs sm:text-sm text-gray-500">
                            <div>We have a meet for every type of car and location.</div>
                        </dd>
                    </div>
                </Col>
            </Row>

            <Row>
                &nbsp;
            </Row>

            <Row>
                <Alert variant="success" className="bg-lightGreen text-white">
                    <h3>Free to use</h3>
                    <p>
                        Our website is free for anyone to join and find events.
                    </p>
                    <p title="Pay for private or semi-private events or a featured image." className="cursor-help">
                        Create a brand new event from free<sup>*</sup>
                    </p>
                </Alert>
            </Row>
        </Container>)
}

export default HomePage;