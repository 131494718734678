import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Container, Row} from "react-bootstrap";
import HelmetHeader from "../../Components/HelmetHeader";
import {EventResponseModel, UserResponseModel, UsersClient} from "../../Client/Client";
import ViewProfileComponent from "../../Components/ViewProfileComponent";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import EditProfileComponent from "../../Components/EditProfileComponent";
import SpinnerComponent from "../../Components/SpinnerComponent";

const ProfilePage: React.FC = () => {
    const history = useHistory();
    const accessToken = useSelector((state: RootState) => state.user.accessToken);
    
    const [editProfile, setEditProfile] = useState<boolean>(false);
    const [userResponse, setUserResponse] = useState<UserResponseModel>();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    const userClient = new UsersClient(process.env.REACT_APP_API_URL);

    let params: any = useParams();
    
    const id: string = params.id;
    console.log(id);

    useEffect(() => {        
        if (id === "@me" && accessToken != null)
        {
            userClient.getCurrentUser(`bearer ${accessToken!}`).then((response) => {
                setUserResponse(response);
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false)
                setErrorMessage("An error occurred retrieving the current user.")
            });
        }
        else if (accessToken != null)
        {
            userClient.getByUsername(`bearer ${accessToken!}`, id).then((response) => {
                if (response != null)
                {
                    setUserResponse(response);
                }
                else
                {
                    setErrorMessage(`Cannot locate user with username ${id}`);
                }
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false)
                setErrorMessage("An error occurred retrieving the current user.")
            });
        }
        
    }, [history.location, accessToken])

    if (isLoading)
    {
        return(<SpinnerComponent />)
    }
    
    if (userResponse == null || errorMessage != "")
    {
        return (
            <>
                <p>
                    &nbsp;
                </p>
                {
                    errorMessage != "" && (
                        <Alert variant="danger" className={"text-center"}>
                            {errorMessage}
                        </Alert>       
                    )
                }
            </>
            )
    }
    
    return (
        <Container>
            <HelmetHeader url={`Profile/@me`} description={`View your profile`} quote={`View your profile`} title={`My Profile`} />
            <Row>
                {
                    id == "@me" && accessToken != null && (
                        <Button type={"button"} variant={editProfile ? 'danger' : 'success' } onClick={() => setEditProfile(!editProfile)}>
                            {editProfile ? 'Cancel Editing' : 'Edit' } Profile
                        </Button>
                    )
                }
                &nbsp;
            </Row>
            <Row>
                <h3>About Me</h3>
            </Row>
            <Row>
                &nbsp;
            </Row>
            {
                !editProfile && (
                    <ViewProfileComponent userResponse={userResponse} isCurrentUser={id === "@me"} />
            )}
            
            {
                editProfile && (
                    <>
                        <EditProfileComponent userResponse={userResponse} />
                    </>
                )
            }
        </Container>
    )
}

export default ProfilePage;