import React, {useEffect, useState} from "react";
import {Alert, Button, Container, Dropdown, Row, Spinner} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {Editor} from "@tinymce/tinymce-react";
import PostItem from "../../Components/PostItem";
import ImageUploading, {ImageListType} from 'react-images-uploading';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {
    AttendingEnum,
    CreatePostModel,
    EventMembersAttendingClient,
    EventMembersClient,
    EventResponseModel,
    EventsClient,
    GetImage,
    PostResponseModel,
    PostsClient,
    UserResponseModel,
    UsersClient
} from "../../Client/Client";
import ImageItem from "../../Components/ImageItem";
import usePageTracking from "../../Components/UsePageTracking";
import Moment from 'moment';
import ShareButtons from "../../Components/ShareButtons";
import HelmetHeader from "../../Components/HelmetHeader";
import ShowUsersAttendingStatusTable from "../../Components/ShowUsersAttendingStatusTable";

const EventPage: React.FC = () => {
    const {accessToken, isAuthenticated} = useSelector((state: RootState) => state.user);
    const postClient = new PostsClient(process.env.REACT_APP_API_URL);
    const eventClient = new EventsClient(process.env.REACT_APP_API_URL);
    const userClient = new UsersClient(process.env.REACT_APP_API_URL);
    const eventMembersClient = new EventMembersClient(process.env.REACT_APP_API_URL);
    const eventMembersAttendingClient = new EventMembersAttendingClient(process.env.REACT_APP_API_URL);
    
    const [event, setEvent] = useState<EventResponseModel>();
    const [posts, setPosts] = useState<Array<PostResponseModel>>();
    const [createPost, setCreatePost] = useState<Boolean>(false);
    const [isButtonSaving, setIsButtonSaving] = useState<boolean>(false);
    const [value, setValue] = useState('');
    const [images, setImages] = useState<Array<ImageListType>>([]);
    const [imagesBase64, setImagesBase64] = useState<Array<string>>([]);

    const [canGetEventPosts, setCanGetEventPosts] = useState<boolean>(true);
    const [canCreateEventPosts, setCanCreateEventPosts] = useState<boolean>(true);

    const [isUserRequestPending, setIsUserRequestPending] = useState<boolean>(false);
    const [isUserRequestRejected, setIsUserRequestRejected] = useState<boolean>(false);
    const [membersPendingApproval, setMembersPendingApproval] = useState<Array<UserResponseModel>>([]);
    const [eventMemberAttending, setEventMemberAttending] = useState<AttendingEnum>();
    const [currentUserIsCreator, setCurrentUserIsCreator] = useState<boolean>(false);
    const [attendingMembersResponse, setAttendingMembersResponse] = useState<Array<EventResponseModel>>([]);
    
    const { id } = useParams<{id: string}>();

    const history = useHistory();

    const featuredImage = (imageUrl: string) => {
        return {
            imageUrl,
            altData: imageUrl
        } as GetImage;
    }

    const onImageChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
      ) => {
        // data for submit
        setImages(imageList as never[]);

        const dataUrls = imageList.map(x => x.dataURL!);
        setImagesBase64(dataUrls);
      };

      const completePost = (save: boolean) => {
        if (!save)
        {
            setValue("");
            setImages([]);
            setCreatePost(false);
            return;
        }

        setIsButtonSaving(true);

        savePost();
      }
      
      const resetState = () => {
          setValue("");
          setImages([]);
          setCreatePost(false);
          setIsButtonSaving(false);
      }

      const savePost = () => {
        const newPost: CreatePostModel = {
                description: value,
                imageBlobs: imagesBase64,
                eventId: event?.id,
                taggedUserNames: []
        }

        postClient.post(`bearer ${accessToken!}`, newPost).then(() => resetState()).catch(() => {
            alert("An unexpected error occurred creating new post.")
        });
      }

      usePageTracking();
    
    useEffect(() => {
        const getEvent = () => {
            let eventsResponse = eventClient.getByUrl(id).then((response) => {
              setEvent(response)  
            }).catch(() => {
                history.push("/Events/Upcoming");
            });
        }
            getEvent();
      }, [canGetEventPosts])

      useEffect(() => {
        const getPosts = async () => {
            let postsResponse = (await postClient.getByEvent(event!.id!));
            setPosts(postsResponse);
        }

        if (event !== undefined)
        {
            const getCurrentUser = async () => {
                return (await userClient.getCurrentUser(`bearer ${accessToken!}`));
            }
    
            if (event != null && event.eventType! > 0)
            {                
                if (event!.eventType == 1)
                {
                    if (accessToken == null)
                    {
                        setCanCreateEventPosts(false);
                    } else {
                        const currentUser = getCurrentUser();
                        currentUser.then((user) => {
                            if (event?.user?.id != user.id)
                            {
                                eventMembersClient.isMember(`bearer ${accessToken!}`, event?.id!)
                                .then(response => {
                                    setCanGetEventPosts(response)
                                    setCanCreateEventPosts(response)
                                })
                            }
                        })
                    }
                }

                if (event!.eventType == 2)
                {
                    if (accessToken == null)
                    {
                        setCanGetEventPosts(false);
                        setCanCreateEventPosts(false);
                    } else {
                        const currentUser = getCurrentUser();
                        currentUser.then((user) => {
                            if (event?.user?.id != user.id)
                            {
                                eventMembersClient.isMember(`bearer ${accessToken!}`, event?.id!)
                                .then(response => {
                                    setCanGetEventPosts(response)
                                    setCanCreateEventPosts(response)
                                })
                            }
                        })
                    }
                }

                if (accessToken != null)
                {
                    getCurrentUser()
                        .then((currentUser) => {
                            if (event.user?.id === currentUser.id)
                            {
                                setCurrentUserIsCreator(true);
                                eventMembersClient.getPendingMembers(`bearer ${accessToken!}`, event!.id!)
                                .then(response => {
                                    setMembersPendingApproval(response);
                                });
                                eventMembersAttendingClient.getEventMembersAttending(`bearer ${accessToken}`, event?.id ?? 0)
                                    .then(response => {
                                        setAttendingMembersResponse(response)
                                    })
                            } else {
                                eventMembersClient.getRejectedMembers(`bearer ${accessToken!}`, event!.id!)
                                .then(response => {
                                    const filteredResponse = response.filter(x => x.id === currentUser.id)
                                    setIsUserRequestRejected(filteredResponse.length > 0);
                                });
                            }
                        });
                }
            }
    
            if (canGetEventPosts)
            {
                getPosts().then(() => setCreatePost(false)).catch(console.error);
            }
            
            if (accessToken != null && accessToken != "" && isAuthenticated && event != null)
            {
                const getAttendingStatus = async () => {
                    return (await eventMembersAttendingClient.getCurrentMemberAttendingStatus(`bearer ${accessToken}`, event.id!));
                }
                
                getAttendingStatus().then(response => {
                    setEventMemberAttending(response.attending ?? AttendingEnum["_0"]);
                })
            }
        }
      }, [event])

      useEffect(() => {
        const getPosts = async () => {
            let postsResponse = (await postClient.getByEvent(event!.id!));
            await setPosts(postsResponse);
        }
        
        if (event !== undefined)
        {
            getPosts().catch(e => console.error(e));
        }
      }, [createPost])

      const requestToJoin = () => {
        eventMembersClient.requestAccess(`bearer ${accessToken}`, event!.id!)
            .then(() => setIsUserRequestPending(true))
            .catch(() => alert("An unexpected error occurred requesting to join the event."))
      }

      const changeMemberStatus = (userId: string, isAccepted: boolean) => {
        eventMembersClient.approveMember(`bearer ${accessToken}`, event?.id!, userId, isAccepted)
            .then(() => {
                let allRemainingMembersPendingApproval = membersPendingApproval.filter(x => x.id != userId);
                setMembersPendingApproval(allRemainingMembersPendingApproval);
            })
            .catch(response => alert(response));
      }

      const urlEncodeGoogleMapLink = (address: string) => {
        address = address.replaceAll(" ", "+");
        return address;
      }
      
      const getAttendingStatusString = (attendingEnum: AttendingEnum) => {
        if(attendingEnum == AttendingEnum._1)
        {
            return "Not Going";
        } else if(attendingEnum == AttendingEnum._2)
        {
            return "Interested";
        } else if(attendingEnum == AttendingEnum._3)
        {
            return "Going";
        } else {
            return "No Response"
        }
      }
      
      const changeAttendingStatus = (body: string) => {
        if (event != null)
        {
            eventMembersAttendingClient.postEventMemberAttendingStatus(`bearer ${accessToken}`, event.id!, body).then(serverResponse => {
                if (serverResponse)
                {
                    if(body == "NotGoing")
                    {
                        setEventMemberAttending(AttendingEnum._1);
                    } else if (body == "Interested")
                    {
                        setEventMemberAttending(AttendingEnum._2);
                    } else if (body == "Going") {
                        setEventMemberAttending(AttendingEnum._3);
                    } else {
                        setEventMemberAttending(AttendingEnum._0);
                    }
                } else {
                    alert("An error occurred whilst trying to change attending status, please try refreshing the page.");
                }
            });
        } else {
            alert("Cannot change attending status, please try refreshing the page.");
        }
          
      }

    return (
        <Container className="eventPage text-center">
            {
                event != null && (
                    <>
                        <HelmetHeader url={`Events/${event.url}`} imageUrl={event.featuredImageUrl} description={`When: ${Moment(event.startDate!).format('ddd Do MMM yy')} at ${Moment(event.startDate!).format('HH:mm')}`} quote={`Check out our event by visiting https://Carmeet.Social/Events/${event.url!}`} title={`${event.name!} Event`} />
                    </>
                )
            }
            {
                membersPendingApproval.length > 0 && (
                    <>
                        <Row>
                        <Alert variant="info">
                            {membersPendingApproval.length} Member{membersPendingApproval.length > 1 ? "s" : ""} pending approval.
                            <br />
                            <Row>
                            {
                                membersPendingApproval.map((memberPending) => {
                                    return(<div key={memberPending.id}>
                                        <strong>{memberPending.userName}</strong>&nbsp;
                                        <Button variant="success" onClick={() => changeMemberStatus(memberPending.id!, true)}>
                                            Approve
                                        </Button>&nbsp;
                                        <Button variant="danger" onClick={() => changeMemberStatus(memberPending.id!, false)}>
                                            Reject      
                                        </Button>
                                    </div>)
                                })
                            }
                            </Row>
                        </Alert>
                        </Row>
                    </>
                )
            }

            {
                canCreateEventPosts && eventMemberAttending != null && !currentUserIsCreator && accessToken != "" && (
                    <Row >
                        <p className={"text-center"}>
                            Attending Status: {getAttendingStatusString(eventMemberAttending!)}
                            <br />
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                                    Change my response
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => changeAttendingStatus("NotGoing")}>Not Going</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeAttendingStatus("Interested")}>Interested</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeAttendingStatus("Going")}>Going</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </p>
                    </Row>
                )
            }
            {
                currentUserIsCreator && (
                    <ShowUsersAttendingStatusTable membersAttendingResponseModel={attendingMembersResponse} />
                )
            }
            <Row>
                &nbsp;
            </Row>
            <Row>
                <h2>
                    {event?.name}
                </h2>
                { canCreateEventPosts && (
                    <p>
                        {
                            event?.location != null &&  (
                                <>
                                    Where:&nbsp;
                                        <b>
                                            {event.location}&nbsp;
                                            
                                            <a href={`https://www.google.com/maps/search/?api=1&query=${urlEncodeGoogleMapLink(event.location)}`} target="_blank">
                                                <i className="fa-solid fa-location-dot"></i>
                                            </a>
                                        </b>        
                                </>
                            )
                        }
                        <br />
                        <br />
                        When: <b>{Moment(event?.startDate!).format('ddd Do MMM yy')} at {Moment(event?.startDate!).format('HH:mm')}</b>
                    </p>
                )}
                <hr />
                </Row>
                <Row>
                {
                    event?.includeFeaturedImage && event?.featuredImageUrl !== null && (
                        <ImageItem image={featuredImage(event.featuredImageUrl!)} className="eventFeaturedImageLarge" />
                    )
                }
                </Row>
                <Row>
                    &nbsp;
                </Row>
            <Row>
                {
                    event != null && (
                        <ShareButtons url={`Events/${event.url}`} quote={`Check out our event by visiting`}></ShareButtons>
                    )
                }
            </Row>

                {
                    !isAuthenticated && (
                        <>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <Button variant="warning" onClick={() => history.push(`/login?ReturnUrl=${history.location.pathname}`)}>
                                    Login now to add posts and comments.
                                </Button>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                        </>
                    )
                }

                <Row>
                <hr />
                {
                    isAuthenticated && canCreateEventPosts && (
                        <>
                            <Button variant="success" onClick={() => setCreatePost(!createPost)}>
                                Create New Post
                            </Button>
                            {
                                createPost && (
                                    <Container>
                            <Row>
                            <Editor 
                                init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor", "searchreplace", "visualblocks", "fullscreen", "insertdatetime", "wordcount", "media",
                                ],
                                toolbar:[
                                    "undo redo | fontsizeselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | removeformat | media",
                                ]
                                }}
                                value={value}
                                onEditorChange={(newValue, editor) => setValue(newValue)}
                            />
                            </Row>
                            <Row>
                                <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={onImageChange}
                                    maxNumber={50}
                                >
                                    {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    errors
                                    }) => (
                                    <>
                                        <div className="upload__image-wrapper">
                                            <button
                                            style={isDragging ? { color: "red" } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            >
                                                Click here to upload your image(s)
                                            </button>
                                            &nbsp;
                                            <button onClick={onImageRemoveAll}>Remove all images</button>
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image.dataURL} alt="" width="100" />
                                                    <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)}>Update</button>
                                                    <button onClick={() => onImageRemove(index)}>Remove</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            {errors != null && errors.maxNumber && <span style={{color: "red"}}>Number of selected images exceed 50</span>}
                                        </div>
                                    </>
                                    )}
                                </ImageUploading>
                            </Row>
                            <Row>    
                                &nbsp;
                            </Row>
                            <Row>
                                <div className="me-2 md-6">
                                    <Button variant="success" onClick={() => completePost(true)} disabled={isButtonSaving}>
                                        { isButtonSaving && (<Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />) }

                                        Submit
                                    </Button>
                                    &nbsp;
                                    <Button variant="warning" onClick={() => completePost(false)} disabled={isButtonSaving}>Cancel</Button>
                                </div>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                        </Container>
                                )
                            }
                        </>
                    )
                }
                &nbsp;
            </Row>
            <div>
                    { canGetEventPosts && (posts?.map(post => {
                        return (<div key={post.id}>
                                <Row className="postItem">
                                    <PostItem post={post} canAddComments={isAuthenticated && canCreateEventPosts} />
                                </Row>
                                <Row>&nbsp;</Row>
                            </div>
                        )}))
                    }
                </div>
                {
                    !canGetEventPosts && event != null && event.eventType == 2 && (
                        <Row>
                            <h4 className="text-center" style={{"color": "red"}}>
                                🔒 Event is locked. <br />
                                Join this event to see the posts and add comments.
                            </h4>
                            {
                                !isUserRequestPending && !isUserRequestRejected && isAuthenticated && (
                                    <Button variant="info" onClick={() => requestToJoin()} >
                                        Request to join.
                                    </Button>
                                )
                            }
                            {
                                isUserRequestPending && (
                                    <h4 className="text-center" style={{"color": "dark-green"}}>
                                        The request to join this event is being reviewed.
                                    </h4>
                                )
                            }
                            {
                                isUserRequestRejected && (
                                    <h4 className="text-center" style={{"color": "red"}}>
                                        The request to join this event has been rejected.
                                    </h4>
                                )
                            }
                        </Row>
                    )
                }
                {
                    !isAuthenticated && (
                        <Row>
                            <Button variant="warning" onClick={() => history.push(`/login?ReturnUrl=${history.location.pathname}`)}>
                                Login now to add posts and comments.
                            </Button>
                        </Row>
                    )
                }
        </Container>
    )
}

export default EventPage;