import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap"
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UsersClient } from "../../Client/Client";
import usePageTracking from "../../Components/UsePageTracking";
import { RootState } from "../../store/store";
import HelmetHeader from "../../Components/HelmetHeader";

const RetrieveUsernameRequestPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [sentSuccessfully, setSentSuccessfully] = useState<boolean>(false);

    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const accessToken = useSelector((state: RootState) => state.user.accessToken);
    
    const history = useHistory();
    const usersClient = new UsersClient(process.env.REACT_APP_API_URL);

    usePageTracking();

    const SendUsernameRequestEmail = () => {
        setSentSuccessfully(false);

        if (email === '' || email.indexOf("@") < 1)
        {
            alert("Please insert a valid email address.");
        }

        usersClient.retrieveUserName(email).then(() => setSentSuccessfully(true));
    }

    const updateEmail = (inputText: string) => {
        setEmail(inputText);
        setSentSuccessfully(false);
    }

    useEffect(() => {
        if (isAuthenticated && accessToken !== "")
        {
            history.push("/");
        }
    }, [isAuthenticated, accessToken])

    return (<Container>
        <HelmetHeader description={"Forgotten your username, don't worry we can help you find where you left it."} title={"Retrieve your username"} url={"RetrieveUsername"} quote={"Retrieve your username"} />
        {
            sentSuccessfully && (
                <Row>
                    <Alert variant="success">
                        If this email address matches a user then an email with the username has been sent.
                    </Alert>
                </Row>
            )
        }
        <Row>
            <Col className="mb-3">
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email Address" onChange={(e) => updateEmail(e.target.value)} />
                    </Form.Group>
                    <Button variant="success" onClick={() => SendUsernameRequestEmail()}>
                        Send Password Reset Email
                    </Button>
                </Form>
            </Col>
        </Row>
    </Container>)
}

export default RetrieveUsernameRequestPage;