import React from "react";
import { Helmet } from 'react-helmet';

interface Props {
    description: string
    title: string
    url?: string
    imageUrl?: string
    quote: string
}

const HelmetHeader: React.FC<Props> = ({ description, title, imageUrl, quote, url }) => {
    return(
        <Helmet>
            <title>{title} - CarMeet.Social</title>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta property="type" content="website" />
            <meta property="url" content={`https://carmeet.social/${url ?? ''}`} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            <meta name="msapplication-TileColor" content="#7ed957" />
            <meta name="msapplication-TileImage" content="/CMS-Green.png" />
            <meta name="theme-color" content="#000000" />
            <meta name="robots" content="noodp" />
            <meta property="title" content={`${title} - CarMeet.Social`} />
            <meta property="quote" content={quote} />
            <meta name="description" content={description} />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${title} - CarMeet.Social`} />
            <meta property="og:quote" content={quote} />
            <meta property="image" content={imageUrl ?? "https://carmeet.social/CMS-Green.png"} />
            <meta property="og:image" content={imageUrl ?? "https://carmeet.social/CMS-Green.png"} />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={`https://carmeet.social/${url}`} />
            <meta property="og:site_name" content="CarMeet.Social" />
            <meta property="og:description" content={description} />
            <link rel="canonical" href={url != null ? `https://carmeet.social/${url}` : "https://carmeet.social/"} />
        </Helmet>);
}

export default HelmetHeader;