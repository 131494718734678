import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { CommentModel, ImagesClient } from "../Client/Client";
import AvatarItem from "./AvatarItem";
import ImageSlideshow from "./ImageSlideshow";

interface Props {
    comment: CommentModel
}

const CommentItem: React.FC<Props> = ({ comment }) => {
    const imageClient = new ImagesClient(process.env.REACT_APP_API_URL);
    
    const [commentImageUrls, setCommentImageUrls] = useState<Array<string>>([]);

    useEffect(() => {
        const getCommentImages = async () => {
            let imagesResponse = (await imageClient.getByComment(comment!.id!));
            setCommentImageUrls(imagesResponse.map(x => x.imageUrl!));
        };

        getCommentImages().catch(console.error);

    }, []);

    return (
        <Container>
            <Row className="postListItem mt-2 pt-2">
            <div dangerouslySetInnerHTML={{ __html: comment.description! }}>
            </div>
                {
                commentImageUrls.length > 0 && (
                    <ImageSlideshow imageUrls={commentImageUrls} />
                )
            }
            &nbsp;
            <AvatarItem userName={comment.user!.userName!} avatarUrl={comment.user?.avatarImageUrl!} /> 

            </Row>
        </Container>
        )
}

export default CommentItem;