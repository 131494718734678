import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import {EventMembersAttendingClient, EventsClient, MemberAttendingResponseModel} from "../Client/Client";
import {Accordion, Table} from "react-bootstrap";

interface Props {
    membersAttendingResponseModel: Array<MemberAttendingResponseModel>
}

const ShowUsersAttendingStatusTable: React.FC<Props> = ({ membersAttendingResponseModel }) => {
    
    return (<Accordion>
        <Accordion.Header>
            Show Users Response
        </Accordion.Header>
        <Accordion.Body>
            <p>
                Going: {membersAttendingResponseModel.filter(x => x.attending == 3).length}
            </p>
            <p>
                Interested: {membersAttendingResponseModel.filter(x => x.attending == 2).length}
            </p>
            <p>
                Not Going: {membersAttendingResponseModel.filter(x => x.attending == 1).length}
            </p>
            <Table striped={true} hover={true}>
                <thead>
                <tr>
                    <th>Username</th>
                    <th>Response</th>
                </tr>
                </thead>
                <tbody>
                {
                    membersAttendingResponseModel.map(member => {
                        return(<tr>
                                <td>{member.user?.userName}</td>
                                <td>
                                    {
                                        member.attending == 1 && (
                                            "Not Going"
                                        ) 
                                    }
                                    {
                                        member.attending == 2 && (
                                            "Interested"
                                        )
                                    }
                                    {
                                        member.attending == 3 && (
                                            "Going"
                                        )
                                    }
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
        </Accordion.Body>
    </Accordion>)
}

export default ShowUsersAttendingStatusTable;