import { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { OidcConfigurationClient } from "../Client/Client";
import { RootState } from '../store/store';
import { setAccessToken, setIsAuthenticated } from "../store/userSlice/userSlice";

const NavBar: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState<boolean | string>(false);

    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const accessToken = useSelector((state: RootState) => state.user.accessToken);

    useEffect(() => {
      if (!isAuthenticated || accessToken == "")
      {
        const accessTokenSession = sessionStorage.getItem("accessToken");
        const refreshTokenSession = sessionStorage.getItem("refreshToken");
        const expireSession = sessionStorage.getItem("expires");

        if (accessTokenSession != null && expireSession != null && new Date(expireSession) > new Date())
        {
          dispatch(setAccessToken(accessTokenSession));
          dispatch(setIsAuthenticated(true));
          return;
        }

        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("expires");
        sessionStorage.removeItem("refreshToken");

        if (refreshTokenSession != null)
        {
          const oidcConfigClient = new OidcConfigurationClient(process.env.REACT_APP_API_URL)
          oidcConfigClient.getClientParameters().then((response: any) => {
            const clientId = process.env.REACT_APP_CLIENT_ID!;
            const details: any = {
              'client_id': clientId,
              'client_secret': response.cs,
              'scope': 'profile offline_access',
              'grant_type': 'refresh_token',
              'refresh_token': refreshTokenSession
          }

          var formBody = [];
          
          for (var property in details) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
          }

          const body = formBody.join("&");

          fetch(`${process.env.REACT_APP_API_URL ?? ''}/connect/token`, {
              method: 'POST',
              cache: 'no-cache',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
              },
              body: body
          })
          .then((response) => response.json())
          .then((data) => {
              if (data.access_token == null)
              {
                  throw(data.error_description);
              }

              let expiryDate = new Date();
              expiryDate.setHours(expiryDate.getHours() + data.expires_in / 60 / 60);

              sessionStorage.setItem("accessToken", data.access_token);
              sessionStorage.setItem("expires", expiryDate.toString())
              sessionStorage.setItem("refreshToken", data.refresh_token);

              dispatch(setAccessToken(data.access_token));
              dispatch(setIsAuthenticated(true));
          }).catch((error) => {
              console.error(error);
          });
          return;
          })
        }
      }
    }, [history.location])

    const pushUrl = (url: string) => {
      setExpanded(false);
      history.push(url);
    }

    const logout = () => {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("expires");
        sessionStorage.removeItem("refreshToken");
        window.location.href = "/";
    }

    return(
        <Navbar bg="light" expand="lg" className='App-header' expanded={expanded === "expanded" }>
      <Container>
        <Navbar.Brand href="/">
            <img src="/CMS-Green.png" alt="Carmeet.Social" className="rounded-circle" width="100px" height="100px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => pushUrl("/")}>Home</Nav.Link>
            <Nav.Link onClick={() => pushUrl("/Events/Upcoming")}>Upcoming Events</Nav.Link>
            <Nav.Link onClick={() => pushUrl("/Events/Previous")}>Previous Events</Nav.Link>
            {
              isAuthenticated != null && isAuthenticated && (
                <>
                    <Nav.Link onClick={() => pushUrl("/Events/Create")}>
                        Create Event
                    </Nav.Link>
                    <Nav.Link onClick={() => pushUrl("/Profile/@me")}>
                        My Profile
                    </Nav.Link>
                    <Nav.Link onClick={() => logout()}>
                        Logout
                    </Nav.Link>
                </>
              )
            }
            {
              (isAuthenticated == null || !isAuthenticated) && (
                <>
                  <Nav.Link onClick={() => pushUrl('/Login')}>
                    Login
                  </Nav.Link>
                  <Nav.Link onClick={() => pushUrl('/Register')}>
                    Register
                  </Nav.Link>
                </>
              )
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}

export default NavBar;