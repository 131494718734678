import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { Button, Container, Row, Spinner } from "react-bootstrap";
import CommentItem from "./CommentItem";
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { CommentModel, CommentsClient, CreateCommentModel } from "../Client/Client";

interface Props {
    postId: string
    canAddComments: boolean
}

const PostComments: React.FC<Props> = ({ postId, canAddComments }) => {
    const accessToken = useSelector((state: RootState) => state.user.accessToken);
    const CommentClient = new CommentsClient(process.env.REACT_APP_API_URL);

    const [comments, setComments] = useState<Array<CommentModel>>([]);
    const [showComments, setShowComments] = useState<boolean>(false);
    const [createComment, setCreateComment] = useState<boolean>(false);
    const [images, setImages] = useState<Array<ImageListType>>([]);
    const [imagesBase64, setImagesBase64] = useState<Array<string>>([]);
    const [value, setValue] = useState('');
    const [isButtonSaving, setIsButtonSaving] = useState<boolean>(false);

    const onImageChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
      ) => {
        // data for submit
        setImages(imageList as never[]);

        const dataUrls = imageList.map(x => x.dataURL!);
        setImagesBase64(dataUrls);
      };

      const completeComment = (save: boolean) => {
        if (!save)
        {
            setValue("");
            setImages([]);
            setCreateComment(!createComment);
            return;
        }
        setIsButtonSaving(true);

        saveComment();
        setValue("");
        setImages([]);

        setCreateComment(false);
        setIsButtonSaving(false);
      }

      const saveComment = () => {
        const newComment: CreateCommentModel = {
            description: value,
            postId: postId,
            imageBlobs: imagesBase64,
            taggedUserNames: []
        }

        CommentClient.create(`bearer ${accessToken!}`, newComment).then(() => {

        }).catch(() => {
                alert("An unexpected error occurred creating new comment.")
        });
      }

    useEffect(() => {
        const getComments = async () => {
            let commentsResponse = (await CommentClient.getByPostId(postId));
            setComments(commentsResponse);
        }
        
        getComments().catch(console.error);
    }, [createComment]);

    return (<Container>
    {
        comments != null && comments.length > 0 && (
            <Row>
                <p className="link" onClick={() => setShowComments(!showComments)}>{showComments ? "Hide" : "Show"} Comments.</p>
            </Row>
        )
    }
    {
        showComments && comments.length > 0 && (
            <>
                {comments.map(comment => <CommentItem comment={comment} key={comment.id} />)}
            </>
        )
    }
    {
        (comments.length == 0 || showComments) && canAddComments && (
            <Row>
                {
                    !createComment && (
                        <p className="link" onClick={() => setCreateComment(!createComment)}>Add New Comment.</p>
                    )
                }
                {
                    createComment && (
                        <Container>
                            <Row>
                            <Editor 
                                disabled={isButtonSaving}
                                init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor", "searchreplace", "visualblocks", "fullscreen", "insertdatetime", "wordcount", "media",
                                ],
                                toolbar:[
                                    "undo redo | fontsizeselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | removeformat | media",
                                ]
                                }}
                                value={value}
                                onEditorChange={(newValue, editor) => setValue(newValue)}
                            />
                            </Row>
                            <Row>
                                <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={onImageChange}
                                    maxNumber={50} 
                                >
                                    {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    errors
                                    }) => (
                                    <>
                                        <div className="upload__image-wrapper">
                                            <Button
                                            style={isDragging ? { color: "red" } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            >
                                                Click here to upload your image(s)
                                            </Button>
                                            &nbsp;
                                            <Button onClick={onImageRemoveAll} variant="danger">Remove all images</Button>
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image.dataURL} alt="" width="100" />
                                                    <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)}>Update</button>
                                                    <button onClick={() => onImageRemove(index)}>Remove</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            {errors != null && errors.maxNumber && <span style={{color: "red"}}>Number of selected images exceed 50</span>}
                                        </div>
                                    </>
                                    )}
                                </ImageUploading>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <div className="me-2 md-6">
                                    <Button variant="success" onClick={() => completeComment(true)} disabled={isButtonSaving}>
                                        { isButtonSaving && (<Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />) }
                                        Submit
                                    </Button>
                                    &nbsp;
                                    <Button variant="warning" onClick={() => completeComment(false)} disabled={isButtonSaving}>
                                        Cancel
                                    </Button>
                                </div>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                        </Container>
                    )
                }
            </Row>
        )
    }
    </Container>)
}

export default PostComments;