import React, { useState } from "react";
import { GetImage } from "../Client/Client";
import ModalComponent from "./ModalComponent";

interface Props {
    image: GetImage
    className?: string
}

const ImageItem: React.FC<Props> = ({ image, className }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>("");

    {
        isOpen && (
            <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} imageUrl={imageUrl} />
        )
    }
    
    return (
    <div>
        <img 
            src={image.imageUrl} 
            alt={image.altData} 
            className={`${ className ?? 'listPostImage m-2'}`} 
            onClick={() => {
                setImageUrl(image.imageUrl!)
                setIsOpen(true)
            }}
        />
      </div>)
}

export default ImageItem;