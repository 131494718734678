import { Spinner } from "react-bootstrap";

const SpinnerComponent: React.FC= () => {
    return (
        <>
            <p>
                &nbsp;
            </p>
            <div className="text-center">
                <Spinner animation="border" variant="success" />
            </div>
        </>
    )
}

export default SpinnerComponent;