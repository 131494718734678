import { useEffect, useState } from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import { UsersClient } from "../Client/Client";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

interface Props {
    minimumAmountToBuy: number
}

const BuyCredits: React.FC<Props> = ({ minimumAmountToBuy }) => {
    const accessToken = useSelector((state: RootState) => state.user.accessToken);
    const [userId, setUserId] = useState<string>("");

    const usersClient = new UsersClient(process.env.REACT_APP_API_URL);
    useEffect(() => {
        const getCurrentUser = async () => {
            const user = await usersClient.getCurrentUser(`bearer ${accessToken!}`);
            setUserId(user.id!);
        }

        getCurrentUser().catch(console.error);
    })

    return (<Container>
        <Row>
            <h4> Buy at least {minimumAmountToBuy} credit(s)</h4>
        </Row>
        <Row>
        <div className="product">
            <div className="description">
                <h3>Event Creation Tokens</h3>
            </div>
        </div>
        {
            userId !== "" && (
                <Form action={`${process.env.REACT_APP_API_URL ?? ""}/api/Payments/Create/Count=${minimumAmountToBuy}&id=${userId}`} method="POST">
                    <Button type="submit" variant="success">
                        Buy More Now
                    </Button>
                </Form>
            )
        }
        </Row>
    </Container>)
}

export default BuyCredits;