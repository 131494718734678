import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { Button, Row } from "react-bootstrap";
import {EventMembersAttendingClient, EventMembersClient, EventModel, GetImage} from "../Client/Client";
import ImageItem from "./ImageItem";
import React from "react";

interface Props {
    event: EventModel
}

const EventItem: React.FC<Props> = ({ event }) => {
    const history = useHistory();

    const GetFeaturedImage = (imageUrl: string)  => {
        return {
            imageUrl,
            altData: imageUrl
        } as GetImage 
    };

    return (<div className="event col-md-12" onClick={() => {history.push(`/Events/${event.url}`)}}>
    <Row>
        {
            event.includeFeaturedImage && event.featuredImageUrl != "" && (
                <div className="col-md-4 py-2 px-4">
                    <ImageItem image={GetFeaturedImage(event.featuredImageUrl!)} className="eventFeaturedImage m-2" />
                    &nbsp;
                </div>
            )
        }
        {
            !event.includeFeaturedImage && event.featuredImageUrl == "" && (
                <div className="col-md-4">
                    &nbsp;
                </div>   
            )
        }
        <div className="col-md-2">
            &nbsp;
        </div>
        <div className="col-md-6 col-sm-12">
            <h4>
                {event.name}
            </h4>
            <p>
                {
                    event.eventType == 0 && (
                        <>
                            Location: {event.location}{event.country != null ? "," : "" } {event.county?.name ?? ""} {event.country?.name ?? ""}
                        </>
                    )
                }
                {
                    event.eventType == 0 && event.distanceToEvent != null && event.distanceToEvent > 0 && (
                        <>
                            <br />
                            Distance: {event.distanceToEvent} miles
                        </>
                    )
                }
            </p>
            <p>
                Date: {Moment(event.startDate!).format('ddd Do MMM yy')} at {Moment(event.startDate!).format('HH:mm')}
            </p>
            <p>
                Created By: {event.user!.userName}
            </p>
        </div>
    </Row>
    <Row>
        &nbsp;
    </Row>
    <Row>
        <Button variant="success">
            View More Information on this meetup.
        </Button>
    </Row>
    <hr />
</div>
    )}

export default EventItem;