import React, {Dispatch, SetStateAction} from "react";
import {Pagination} from "react-bootstrap";

interface Props {
    currentPage: number
    totalPages: number
    setCurrentPage: (currentPage: number) => void;
}

const PaginationComponent: React.FC<Props> = ({ currentPage, totalPages, setCurrentPage }) => {
    const pages: Array<number> = Array.from(Array(totalPages).keys());
    
    return (<>
                {
                    pages.map((index) => {
                        return (<Pagination.Item active={index + 1 == currentPage} key={index} onClick={() => {setCurrentPage(index + 1); window.scrollTo(0, 0)}}>
                            {index + 1}
                        </Pagination.Item>)
                    })
                }
        </>)
}

export default PaginationComponent;