import React from "react";
import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from "react-share";

interface Props {
    quote: string
    url: string
}
const ShareButtons: React.FC<Props> = ({quote, url }) => {
    return<div className="text-center">
        Share on Social Media: <br />
            <FacebookShareButton
                url={`https://carmeet.social/${url}`}
                quote={quote}
                className="facebook-button">
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            &nbsp;
            <WhatsappShareButton
                url={`https://carmeet.social/${url}`}
                title={quote}
                className="whatsapp-button">
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
        </div>
}

export default ShareButtons;