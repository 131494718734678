import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    CountryModel,
    CountyModel,
    CreateEventModel,
    EventsClient,
    FilterClient,
    UsersClient
} from "../../Client/Client";
import BuyCredits from "../../Components/BuyCredits";
import usePageTracking from "../../Components/UsePageTracking";
import { RootState } from "../../store/store";
import HelmetHeader from "../../Components/HelmetHeader";
import moment from "moment";
import ConfirmationComponent from "../../Components/ConfirmationComponent";

const CreateEventPage: React.FC = () => {
    const [userCredits, setUserCredits] = useState<number>(0);
    const [userCreditsCost, setUserCreditsCost] = useState<number>(0);
    const [selectedEventType, setSelectedEventType] = useState<number>(0);
    const [eventName, setEventName] = useState<string>("");
    const [eventLocation, setEventLocation] = useState<string>("");
    const [eventDateTime, setEventDateTime] = useState<string>("");
    const [eventCountry, setEventCountry] = useState<CountryModel>();
    const [eventCounty, setEventCounty] = useState<CountyModel>();
    const [includeFeaturedImage, setIncludeFeaturedImage] = useState<boolean>(false);
    const [featuredImageBlob, setFeaturedImageBlob] = useState<string>();
    const [uploadedImage, setUploadedImage] = useState<ImageListType>();
    const [isButtonSaving, setIsButtonSaving] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    
    const [filterCountries, setFilterCountries] = useState<Array<CountryModel>>([])
    const [filterCounties, setFilterCounties] = useState<Array<CountyModel>>([]);

    const [purchasedTokens, setPurchasedTokens] = useState<boolean>(false);
    const [successPurchasedTokens, setSuccessPurchasedTokens] = useState<boolean>(false);

    const history = useHistory();
    

    const usersClient = new UsersClient(process.env.REACT_APP_API_URL);
    const eventsClient = new EventsClient(process.env.REACT_APP_API_URL);
    const filterClient = new FilterClient(process.env.REACT_APP_API_URL);
    const accessToken = useSelector((state: RootState) => state.user.accessToken);

    usePageTracking();

    useEffect(() => {
        if (accessToken == null)
        {
            history.push(history.location);
            return;
        }

        const getCurrentUser = async () => {
            const user = await usersClient.getCurrentUser(`bearer ${accessToken!}`);
            setUserCredits(user.credits ?? 0);
        }

        getCurrentUser().catch(console.error);

        if (history.location.search.indexOf("purchased") > 0)
        {
            setPurchasedTokens(true);
            if (history.location.search.indexOf("success") > 0)
            {
                setSuccessPurchasedTokens(true);
            } else {
                setSuccessPurchasedTokens(false);
            }
        }
        
        filterClient.getCountries().then((countriesResponse) => {
            setFilterCountries(countriesResponse);
        })

    }, [history.location])
    
    useEffect(() => {
        if (eventCountry != null)
        {
            filterClient.getCounties(eventCountry).then((countiesResponse) => {
                setFilterCounties(countiesResponse);
            })
        }
    }, [eventCountry])

    const changeSelectedEventType = (typeId: number) => {
        setUserCreditsCost(typeId + (includeFeaturedImage ? 1 : 0));
        setSelectedEventType(typeId);
    }

    const changeIncludeFeaturedImage = () => {
        const currentCost = includeFeaturedImage ? 1 : -1;
        setUserCreditsCost(userCreditsCost - currentCost);
        setIncludeFeaturedImage(!includeFeaturedImage);
    }
    
    const createEventIfPassesValidation = () => {
        if (!includeFeaturedImage || featuredImageBlob == null)
        {
            setShow(true);
            return;
        }
        
        createEvent();
    }
    
    const addFeaturedImage = () => {
        setShow(!show);
        setIncludeFeaturedImage(true);
    }

    const createEvent = async () => {
        setShow(false);
        setIsButtonSaving(true);
        
        if (includeFeaturedImage && featuredImageBlob == null) {
            setIncludeFeaturedImage(false);
        }

        // noinspection SpellCheckingInspection
        const eventBody: CreateEventModel = {
            name: eventName,
            location: eventLocation,
            startDate: `${moment(eventDateTime).utc().format("yyyy-MM-DDTHH:mm")}Z`,
            eventType: selectedEventType,
            includeFeaturedImage: includeFeaturedImage,
            imageBlob: featuredImageBlob,
            countryId: eventCountry?.id,
            countyId: eventCounty?.id
        }

        eventsClient.post(`bearer ${accessToken!}`, eventBody)
            .then((resp) =>
                history.push(resp)
            )
            .catch(() => {
                alert("An unexpected error occurred.");
                setIsButtonSaving(false);
            });
    }

    const onImageChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
      ) => {
        // data for submit
        setUploadedImage(imageList as never[]);
        setFeaturedImageBlob(imageList.length > 0 ? imageList[0].dataURL! : undefined);
      };

    return(<Container>
        <HelmetHeader url={`/Events/Create`} description={`Drive more engagement and interactivity to your event`} quote={`Add your own car meetup`} title={`Create an Event`} />
        {
            purchasedTokens && successPurchasedTokens && (
                <Alert variant="info">
                    Successfully purchased tokens.
                </Alert>
            )
        }
        {
            purchasedTokens && !successPurchasedTokens && (
                <Alert variant="danger">
                    An error occurred whilst purchasing the requested tokens.
                </Alert>
            )
        }
        <Row>
            <p className="h4">
                Current Credits: {userCredits}
            </p>
            <p className={userCredits - userCreditsCost < 0 ? "text-danger" : ""}>
                Amount Left After Creating Event: {userCredits - userCreditsCost}
            </p>
            {
                userCredits - userCreditsCost < 0 && (
                    <BuyCredits minimumAmountToBuy={(userCredits - userCreditsCost) * -1} />
                )
            }
        </Row>
        <Row>
                Select Event Type:
                <Form>
                    <Form.Check
                        inline
                        label="Public"
                        name="group1"
                        type="radio"
                        checked={selectedEventType == 0}
                        onChange={() => null}
                        onClick={() => changeSelectedEventType(0)}
                    />
                     <Form.Check
                        inline
                        label="Semi-Private"
                        name="group1"
                        type="radio"
                        title='This is viewable to all users but only members can create posts and comments.'
                        checked={selectedEventType == 1}
                        onChange={() => null}
                        onClick={() => changeSelectedEventType(1)}
                    />
                    <Form.Check
                        inline
                        label="Private"
                        name="group1"
                        type="radio"
                        checked={selectedEventType == 2}
                        onChange={() => null}
                        onClick={() => changeSelectedEventType(2)}
                    />
                </Form>
        </Row>
        <Row>
            &nbsp;
        </Row>
        <Row>
            <Form>
                <Form.Check 
                    type="switch"
                    id="featuredImage"
                    label="Include Featured Image"
                    checked={includeFeaturedImage}
                    onChange={() => changeIncludeFeaturedImage()}
                />
            </Form>
        </Row>
        <Row>
            { includeFeaturedImage && (
                <ImageUploading
                value={uploadedImage ?? []}
                onChange={onImageChange}
                maxNumber={50}
            >
                {({
                imageList,
                onImageUpload,
                onImageRemove,
                isDragging,
                dragProps
                }) => (
                <>
                    <div className="upload__image-wrapper">
                        <Button
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                        >
                            Click here to {featuredImageBlob ? "edit" : "upload"} your image
                        </Button>
                        &nbsp;
                        {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image.dataURL} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                <Button variant="danger" onClick={() => onImageRemove(index)}>Remove</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
                )}
            </ImageUploading>
            )}
        </Row>
        <Row>
            &nbsp;
        </Row>
        <Row>
            <Form>
                <InputGroup size="lg">
                    <InputGroup.Text id="inputGroup-sizing-lg">Name</InputGroup.Text>
                    <Form.Control type="text" placeholder="Static Car Meet" aria-describedby="inputGroup-sizing-lg" onChange={(e) => setEventName(e.target.value)} />
                </InputGroup>
                <InputGroup size="lg">
                <InputGroup.Text id="inputGroup-sizing-lg">Location</InputGroup.Text>
                    <Form.Control type="text" placeholder="Caffeine & Machine" onChange={(e) => setEventLocation(e.target.value)}/>
                </InputGroup>
                {
                    filterCountries.length > 0 && (
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">Country</InputGroup.Text>
                            <Form.Select aria-label="Select Country" size="lg" onChange={e => setEventCountry(filterCountries.find(x => x.id == Number.parseInt(e.target.value)))}>
                                <option>Select a country</option>
                                {
                                    filterCountries.map(filterCountry => {
                                        return (<option value={filterCountry.id} key={filterCountry.id}>
                                            {filterCountry.name}
                                        </option>)
                                    })
                                }
                            </Form.Select>
                            <br />
                        </InputGroup>
                    )
                }
                {
                    filterCounties.length > 0 && (
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">County</InputGroup.Text>
                            <Form.Select aria-label="Select County" size="lg" onChange={e => setEventCounty(filterCounties.find(x => x.id == Number.parseInt(e.target.value)))}>
                                <option>Select a county</option>
                                {
                                    filterCounties.map(filterCounty => {
                                        return (<option value={filterCounty.id} key={filterCounty.id}>
                                            {filterCounty.name}
                                        </option>)
                                    })
                                }
                            </Form.Select>
                            <br />
                        </InputGroup>
                    )
                }
                <InputGroup size="lg">
                    &nbsp;
                </InputGroup>
                <InputGroup size="lg">
                    <InputGroup.Text id="inputGroup-sizing-lg">Start Date & Time</InputGroup.Text>
                    <Form.Control type="datetime-local" aria-describedby="inputGroup-sizing-lg" onChange={(e) => setEventDateTime(e.target.value)} />
                </InputGroup>
            </Form>
        </Row>
        <Row>
            &nbsp;
            {
                userCredits - userCreditsCost >= 0 && eventName != "" && eventLocation != "" && eventDateTime != "" && (
                    <Container>
                        <Row>
                            <br />
                            <Button variant="success" onClick={() => {createEventIfPassesValidation()}} disabled={isButtonSaving}>
                                { isButtonSaving && (<Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />) }
                                Create Event
                            </Button>
                        </Row>
                    </Container>
                )
            }
        </Row>
        {
            show && (
                <ConfirmationComponent 
                    okText={"Add an image"} 
                    cancelText={"Continue without an image"} 
                    confirmation={'Would you like to add a featured image to your event?'} 
                    show={show} 
                    proceed={() => addFeaturedImage()} 
                    cancel={() => createEvent()} 
                />
            )
        }
    </Container>)
}

export default CreateEventPage;