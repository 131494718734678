import React, {useState} from "react";
import {Alert, Button, Container, Form, InputGroup, Row} from "react-bootstrap";
import HelmetHeader from "../Components/HelmetHeader";
import {AdvertiseClient, AdvertiseModel} from "../Client/Client";
import SpinnerComponent from "../Components/SpinnerComponent";

const AdvertiseWithUsPage: React.FC = () => {
    const advertiseClient = new AdvertiseClient(process.env.REACT_APP_API_URL);

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [websiteUrl, setWebsiteUrl] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [successfulSentRequest, setSuccessfulSentRequest] = useState<boolean>(false);
    const [sentRequest, setSentRequest] = useState<boolean>(false);
    const [sendingRequest, setSendingRequest] = useState<boolean>(false);
    
    const sendRequest = () => {
        
        if (firstName === "")
        {
            alert("Please enter a valid first name");
            return;
        }
        
        if (lastName === "")
        {
            alert("Please enter a valid last name");
            return;
        }
        
        if (emailAddress === "" || emailAddress.indexOf("@") < 2)
        {
            alert("Please enter a valid email address");
            return;
        }
        
        if (phoneNumber === "" || phoneNumber.length < 5)
        {
            alert("Please enter a valid phone number");
            return;
        }
        
        const advertiseBody: AdvertiseModel = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            phoneNumber: phoneNumber,
            websiteUrl: websiteUrl,
            message: message
        }

        setSendingRequest(true);
        setSentRequest(true);
        
        advertiseClient.create(advertiseBody).then(r => {
            setSendingRequest(false);
            setSuccessfulSentRequest(r.valueOf());
        })
    }

    if (sendingRequest)
    {
        return(<SpinnerComponent />)
    }
    
    return (
        <>
            <HelmetHeader url={`/AdvertiseWithUs`} description={`Drive more business to your brand by advertising with us`} quote={``} title={`Advertise with us`} />
            <Container className="text-center">
                {
                    sentRequest && successfulSentRequest && (
                        <Row>
                            <Alert variant="success">
                                Thank you for reaching out to advertise with us, a member of our team will be in touch soon to discuss this further.
                            </Alert>
                        </Row>
                    )
                }
                {
                    sentRequest && !successfulSentRequest && (
                        <Row>
                            <Alert variant="danger">
                                An unexpected error has occurred, please check the details below and retry.
                            </Alert>
                        </Row>
                    )
                }
                <Row>
                    <h3>
                        Start a conversation with us to advertise your brand on CarMeet.Social
                    </h3>
                    <p>
                        Fill in the form below to start a conversation with us in regards to advertising your brand on our site.
                    </p>
                </Row>
                <Row>
                    <Form>
                        <InputGroup size="lg">
                            <InputGroup.Text id="inputGroup-sizing-lg">First Name</InputGroup.Text>
                            <Form.Control type="text" placeholder="Jane" aria-describedby="inputGroup-sizing-lg" onChange={(e) => setFirstName(e.target.value)} required={true} />
                        </InputGroup>
                        <InputGroup size="lg">
                            <InputGroup.Text id="inputGroup-sizing-lg">Last Name</InputGroup.Text>
                            <Form.Control type="text" placeholder="Doe" aria-describedby="inputGroup-sizing-lg" onChange={(e) => setLastName(e.target.value)} required={true} />
                        </InputGroup>
                        <InputGroup size="lg">
                            <InputGroup.Text id="inputGroup-sizing-lg">Email Address</InputGroup.Text>
                            <Form.Control type="text" placeholder="noreply@carmeet.social" onChange={(e) => setEmailAddress(e.target.value)} required={true} />
                        </InputGroup>
                        <InputGroup size="lg">
                            <InputGroup.Text id="inputGroup-sizing-lg">Phone Number</InputGroup.Text>
                            <Form.Control type="text" placeholder="+44 (0)1234 567890" onChange={(e) => setPhoneNumber(e.target.value)} />
                        </InputGroup>
                        <InputGroup size="lg">
                            <InputGroup.Text id="inputGroup-sizing-lg">Website Address (Optional)</InputGroup.Text>
                            <Form.Control type="text" placeholder="https://carmeet.social/" onChange={(e) => setWebsiteUrl(e.target.value)} />
                        </InputGroup>
                        <InputGroup size="lg">
                            <InputGroup.Text id="inputGroup-sizing-lg">Additional Info</InputGroup.Text>
                            <Form.Control type="text" placeholder="E.g. We are launching a new product and looking for a longer term advertising partnership." onChange={(e) => setMessage(e.target.value)} />
                        </InputGroup>
                    </Form>
                </Row>
                <Row>
                    &nbsp;
                </Row>
                <Row className={"text-center"}>
                    <Button variant={"success"} onClick={() => sendRequest()}>
                        Submit
                    </Button>
                </Row>
            </Container>
        </>
    )
}

export default AdvertiseWithUsPage;