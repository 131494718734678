import { Container, Row } from "react-bootstrap";
import Moment from 'moment';
import React, { useEffect, useState } from "react";
import PostComments from "./PostComments";
import AvatarItem from "./AvatarItem";
import { ImagesClient, PostModel } from "../Client/Client";
import ImageSlideshow from "./ImageSlideshow";

interface Props {
    post: PostModel
    canAddComments: boolean
}

const PostItem: React.FC<Props> = ({ post, canAddComments }) => {
    const imageClient = new ImagesClient(process.env.REACT_APP_API_URL);
    const [postImageUrls, setPostImageUrls] = useState<Array<string>>([]);

    useEffect(() => {
        const getImages = async () => {
            let imagesResponse = (await imageClient.getByPost(post!.id!));
            setPostImageUrls(imagesResponse.map(x => x.imageUrl!));
        }
        getImages().catch(console.error);
      }, [])

    return (<Container className="postListItem">
        <p dangerouslySetInnerHTML={{ __html: post.description! }}>
        </p>

            {
                postImageUrls.length > 0 && (
                    <ImageSlideshow imageUrls={postImageUrls} />
                )
            }

            <br />

        <AvatarItem userName={post!.user!.userName!} avatarUrl={post!.user!.avatarImageUrl!} />

        <p>
            <>
                {Moment(post.updated!).format('Do MMM yy')} at {Moment(post.updated!).format('HH:mm')}
            </>
        </p>
        <Row>
            <PostComments postId={post.id!} canAddComments={canAddComments} />
        </Row>

    </Container>)
}

export default PostItem;