import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import ReactGA from 'react-ga4';

const container = document.getElementById('root')!;
const root = createRoot(container);
const TRACKING_ID = "G-LX40LBKMXF"; 
ReactGA.initialize(TRACKING_ID);

const rootElement = document.getElementById(("root"));

if (rootElement != null && rootElement.hasChildNodes()) {
    ReactDOM.hydrate((
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>), rootElement
    );
} else {
    root.render((
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>)
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
