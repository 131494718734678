const GoogleTag: any = () => {
    return {__html: `<script async src="https://www.googletagmanager.com/gtag/js?id=G-LX40LBKMXF"></script>
            <script>
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-LX40LBKMXF');
            </script>`};
}

export default GoogleTag;